<template>
  <div>
    <!-- Start Footer Section -->
    <footer>
      <div class="container">
        <div class="row">
          <!-- Start Contact Widget -->
          <div class="col-md-4 col-sm-6 col-xs-12">
            <div class="footer-widget footer-logo">
              <h4>
                <router-link to="/" class="no-active-style" :exact="true">
                  <img
                    src="/assets/img/logos/asamai_properties_main_logo_001.png"
                    class="img-responsive"
                    alt="ASAMAI PROPERTIES LOGO"
                /></router-link>
              </h4>
            </div>
          </div>
          <!-- End Contact Widget -->

          <!-- Start Address Widget -->
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div id="fotter-main" class="footer-widget">
              <h4>
                Contact us
              </h4>
              <ul class="address">
                <li>
                  <i class="icon-location-pin"></i> 12 Briton Street<br />
                  LE3 0AA<br />
                  Leicester<br />
                  United Kingdom
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><i class="icon-screen-tablet"></i> 0772 336 3184</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><i class="icon-phone"></i> 0116 296 4482</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><i class="icon-envelope"></i>
                    info@asamaiproperties.co.uk</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- End Address Widget -->

          <!-- Start latest Work Posts Widget -->
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="footer-widget">
              <h4>OPENING TIMES</h4>
              <ul>
                <li>
                  Open Mon-Fri: 9:00am-6:00pm
                </li>
                <li>
                  Saturday: 10:00am-6:00pm
                </li>
                <li>
                  Sunday CLOSED
                </li>
              </ul>
              <h4 style="margin-top:10px;">
                Useful links
              </h4>
              <div class="footer-nav">
                <ul>
                  <li>
                    <router-link
                      to="/service-landlords"
                      class="no-active-style"
                    >
                      <i class="icon-arrow-right"></i>LANDLORDS</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/service-tenants" class="no-active-style">
                      <i class="icon-arrow-right"></i>TENANTS</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/property-for-rent"
                      class="no-active-style"
                    >
                      <i class="icon-arrow-right"></i>RENT</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/property-for-sale"
                      class="no-active-style"
                    >
                      <i class="icon-arrow-right"></i>BUY</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- End latest Work  Widget -->

          <!-- Start Text Widget -->
          <div class="col-md-2 col-sm-6 col-xs-12">
            <div class="footer-widget">
              <Account></Account>
            </div>
          </div>
          <!-- End Text Widget -->
        </div>
      </div>
    </footer>
    <!-- End Footer Section -->

    <!-- Start Copyright -->
    <div id="copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="site-info text-center">
              <p>
                For your software needs contact
                <span style="color:#0094ff">programmer@softmighty.com</span> :
                mobile-apps, web-apps, websites, data-driven apps, consultancy
                and IT Strategy advisory services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Copyright -->
    <!-- Go To Top Link -->
    <a href="#" class="back-to-top">
      <i class="icon-arrow-up"></i>
    </a>
  </div>
</template>
<script>
export default {
  components: {
    Account: () => import("./UserAccount")
  },
  mounted() {
    //Back Top Link active
    var offset = 200;
    //var duration = 500;
    window.$(window).scroll(function() {
      if (window.$(this).scrollTop() > offset) {
        window.$(".back-to-top").fadeIn(400);
      } else {
        window.$(".back-to-top").fadeOut(400);
      }
    });

    window.$(".back-to-top").on("click", function(event) {
      event.preventDefault();
      window.$("html, body").animate(
        {
          scrollTop: 0
        },
        600
      );
      return false;
    });
  }
};
</script>
